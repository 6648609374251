@import 'src/shared/styles/mixins';
@import 'src/shared/styles/variables';

.layout {
	min-height: 40rem;
	width: 100%;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.19);
	border: 2px dashed gray;
	border-radius: 0.375rem;
	z-index: 0;

	&.presentation {
		aspect-ratio: 9/4;
	}

	.react-grid-item.react-grid-placeholder {
		@include theme-aware('background-color', 'background-shade-content');
	}

	.zi-chart-container {
		height: 100%;
		padding: 0rem;

		div:first-of-type {
			display: flex;
			justify-content: center;
		}
	}

	.grid-border {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.19);
	}

	.grid-layout-widget {
		border: 2px dashed gray;
		border-radius: 0.375rem;
		cursor: grab;

		.delete-span {
			position: absolute;
			top: -0.2rem;
			right: 0.1rem;

			&:hover {
				cursor: pointer;
			}
		}

		.edit-span {
			position: absolute;
			top: -0.2rem;
			right: 1.1rem;

			&:hover {
				cursor: pointer;
			}
		}

		.react-resizable-handle {
			position: absolute;
			width: 0.375px;
			height: 0.375px;
		}

		.pf-c-input-group {
			input {
				border-bottom-right-radius: 0rem;
				border-top-right-radius: 0rem;
			}

			.button-accept {
				border-radius: 0rem;
				color: green;

				&:hover {
					font-weight: bold;
				}
			}

			.button-cancel {
				border-bottom-left-radius: 0rem;
				border-top-left-radius: 0rem;
				color: red;

				&:hover {
					font-weight: bold;
				}
			}
		}
	}

	.grid-layout-empty-widget {
		height: 100% !important;
		width: 100% !important;

		.react-resizable-handle {
			display: none;
		}
	}
}

.layout.display-only {
	box-shadow: none;
	border: none;

	.grid-layout-widget {
		border: none;

		.delete-span,
		.edit-span {
			display: none;
		}
	}

	.grid-layout-widget-text {
		border: none;

		.delete-span,
		.edit-span {
			display: none;
		}
	}
}
