@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.report-container {
	height: 100%;

	.view-report-container {
		.details-container {
			.details {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.form-container {
					flex-basis: 25%;

					.pf-c-form {
						display: flex;

						.folder-container {
							display: flex;
							gap: 1rem;
							align-items: flex-end;
						}
					}
				}
			}
		}
		.misc-actions {
			.lbl-entity-actions {
				bottom: 0;
			}
		}
		.filters {
			.date-selector-container {
				padding: 0.25rem;
				background-color: map-get($basic-zi-colors, 'light');
				border: 0.0625rem solid map-get($basic-zi-colors, 'accent-light');
				display: flex;
				justify-content: center;
				align-items: center;
				height: 2.25rem;

				.btn-dateperiod {
					border: unset !important;
					height: 1.875rem;
					border-radius: 0.25rem !important;
					margin: 0.3125rem 0 0.3125rem 0 !important;
					padding: 0 0.3125rem 0 0.3125rem !important;
				}
			}

			.custom-button-chip {
				display: flex;
				align-items: center;
				background-color: map-get($basic-zi-colors, 'light');
				border: 0.0625rem solid map-get($basic-zi-colors, 'accent-light');
				width: fit-content;
				height: 2.25rem;

				&.invalid {
					border: 0.125rem solid map-get($basic-zi-colors, 'error-red-border');
					color: map-get($basic-zi-colors, 'error-red-text');
					button {
						color: map-get($basic-zi-colors, 'error-red-text');
					}
				}

				.label {
					margin: 0.25rem;
					font-weight: bold;
					padding: 0.25rem;
					border-radius: 0.25rem;
					height: 1.875rem;
					&:hover {
						cursor: pointer;
						background-color: map-get($basic-zi-colors, 'accent-dark');
					}
				}
			}
		}
	}
}
