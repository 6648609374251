@import 'src/shared/styles/mixins';
@import 'src/shared/styles/variables';

.pf-c-page__main-section {
	@include theme-aware('background', 'brand-primary');
	margin: 0;

	&.sub-nav {
		max-height: 20rem;
		transition: max-height 0.5s ease-in;
		padding: 1rem 5rem;
		@include theme-aware('color', 'text-secondary');

		a {
			@include theme-aware('color', 'text-secondary');

			&:hover {
				@include theme-aware('color', 'text-secondary-hover');
			}
		}

		.icon:hover {
			@include theme-aware('color', 'text-secondary-hover');
			cursor: pointer;
		}

		&.collapsed {
			max-height: 6.5rem;
			transition: max-height 0.5s ease-in;

			.hidden-when-collapsed {
				visibility: hidden;
				transition: visibility 0.5s ease-out;
			}
		}

		&.no-title.collapsed {
			max-height: 3.5rem;
		}

		.breadcrumbs {
			> div {
				margin-right: 0.4rem;
			}
			a {
				font-size: $text-sm;
			}
			p {
				@include theme-aware('color', 'text-secondary-hover');
			}
		}
	}
}

.pf-c-sidebar__content {
	margin: 0 !important;
	padding-top: $space-xl;
	padding-bottom: $space-xl;
	padding-left: $space-xl;
	padding-right: 5rem;
	@include theme-aware('background', 'background-shade-content');

	&.no-side-bar {
		padding-left: 5rem;
	}
}

.page-content {
	.pf-c-page__main-section {
		margin-bottom: 0 !important;
	}
}

.zi-sidebar {
	.pf-c-sidebar__main {
		align-items: stretch;
		height: 100%;

		.parent-tabs {
			li button {
				font-size: $text-lg;
			}
		}

		.child-tabs li button {
			font-size: $text-md;
		}
	}

	.pf-c-tree-view__list {
		margin: 0;
	}

	.pf-c-sidebar__panel {
		padding-top: var(--pf-c-page__main-section--PaddingTop);
		@include theme-aware('background', 'background-shade-side');
		min-width: 20rem;

		.zi-sidebar-headers {
			margin: 0 1rem 1rem 1rem;
		}

		.loading-container {
			height: calc(100vh - 125px);
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&.right-sidebar-panel {
			min-width: 24rem;
			transition: width 1s;
			.collapse-icon,
			.collapse-icon:focus {
				top: 33rem;
				position: absolute;
				margin-left: -1rem;
				background-color: white;
				border: solid 0.05rem darkgrey;
				outline: none;
				border-radius: 1rem;
				padding-left: 0.3rem;
				padding-right: 0.3rem;
				cursor: pointer;
			}
			&.closed {
				width: 1rem;
				min-width: 1rem;
				flex-basis: auto;
			}
		}

		&.left-sidebar-panel {
			transition: width 1s;
			.collapse-icon-left,
			.collapse-icon-left:focus {
				top: 33rem;
				position: absolute;
				margin-left: -1rem;
				background-color: white;
				border: solid 0.05rem darkgrey;
				outline: none;
				border-radius: 1rem;
				padding-left: 0.3rem;
				padding-right: 0.3rem;
				cursor: pointer;
				bottom: 450px;
				left: 320px;
				z-index: 999999;
			}

			.zi-sidebar-links,
			.zi-sidebar-headers {
				display: auto;
			}

			&.closed-left {
				width: 1rem;
				min-width: 1rem !important;
				flex-basis: auto;

				.collapse-icon-left,
				.collapse-icon-left:focus {
					position: absolute;
					left: 18px;
				}

				.zi-sidebar-links,
				.zi-sidebar-headers {
					display: none;
				}
			}
		}
	}

	.pf-c-tree-view {
		padding-top: 0;
		padding-bottom: 0rem;

		.pf-c-tree-view__node {
			padding-top: $space-xs;
			padding-bottom: $space-xs;
			@include theme-aware('color', 'text-primary');

			&:is(.pf-m-current),
			&:hover,
			&:active {
				@include theme-aware('background', 'brand-primary');
				@include theme-aware('color', 'text-secondary');
				cursor: pointer;
				text-decoration: none;
				padding-top: $space-xs;
				padding-bottom: $space-xs;
				svg {
					@include theme-aware('color', 'text-secondary');
				}
			}

			.pf-c-tree-view__node-icon {
				padding-right: 0rem;
				width: 1.75rem;
			}
		}
	}

	.page-main-content {
		.pf-c-card {
			//BOX SHADOW
			box-shadow: 0 1px 3px 0 map-get($basic-zi-colors, 'accent-dark'),
				0px 1px 1px 0px map-get($basic-zi-colors, 'accent-dark');

			border-radius: 0.375rem;
		}

		.pf-c-card__body {
			background: inherit;
			border-radius: 0.375rem;
		}
		.no-side-bar {
			padding-left: 5rem;
			padding-right: 5rem;
		}
	}

	.pf-c-text-input-group,
	.pf-c-select {
		margin: auto;
	}
	.pf-c-sidebar__main {
		.pf-c-sidebar__panel {
			background: #fafafa;

			.zi-sidebar-links {
				.pf-c-text-input-group {
					width: 90%;
				}
			}

			.pf-c-tab-content {
				overflow-y: auto;
				height: 40rem;
			}
		}
	}
}

.text-underline {
	text-decoration: underline;
}

.sidebar-spacer {
	padding-top: $space-md;
	display: flex;
}

.lbl-dashboard {
	padding-left: $space-xxl;
}

// patternfly dropdown button styling
.pf-c-button.pf-m-primary,
.btn-dropdown-container > .pf-c-dropdown__toggle {
	height: 2.5rem;
	border-radius: $border-radius-all;
	@include theme-aware('background', 'brand-primary');
	@include theme-aware('color', 'text-secondary');
	font-weight: normal;
	.pf-c-dropdown__toggle-text {
		padding-left: 1.3rem;
	}

	&:hover {
		@include theme-aware('color', 'text-secondary');
	}

	&::before {
		border: none;
	}
}

.pf-c-truncate {
	--pf-c-truncate--MinWidth: 9ch !important;
}

.font-size-xll {
	font-size: $text-xll;
}

.font-size-xl {
	font-size: $text-xl;
}

.font-size-lg {
	font-size: $text-xl;
}

.font-size-md {
	font-size: $text-md;
}

.font-size-sm {
	font-size: $text-smm;
}

.nudge-right {
	padding-right: 0.5rem;
}

.nudge-left {
	padding-left: 0.5rem;
}

.nudge-left-x2 {
	padding-left: 1rem;
}

.pt-2rem {
	padding-top: $space-xl;
}

.alert-action > svg {
	float: right;
}

.alert-dropdown > ul > li {
	width: 10.313rem;
	a {
		font-size: $text-sm;
		justify-content: space-between;
	}
}

.home-page-subbar:not(.collapsed) {
	max-height: 30rem; /*manually override pattern fly default */
}

.home-page-subbar.collapsed .dv-trend-line {
	height: 0; /* Set initial height to 0 */
	opacity: 0; /* Initially make it fully transparent */
	transition: height 0.3s ease, opacity 0.3s ease;
}

.home-page-subbar:not(.collapsed) .dv-trend-line {
	height: auto; /* Allow content to determine height */
	opacity: 1; /* Make it fully visible */
	transition: height 0.3s ease, opacity 0.3s ease;
}

.spacer-sm {
	height: 2rem;
	display: block;
}

.spacer-xs {
	height: 1rem;
	display: block;
}

.spacer-l {
	height: 4rem;
	display: block;
}

.dv-report-percentage {
	position: relative;
	bottom: 0.938rem;
}

.dv-trend-line {
	border-radius: 1.25rem;
}

.dv-trend-line > div {
	box-shadow: 0 1px 1px 0 map-get($basic-zi-colors, 'accent-dark'),
		0 1px 1px 0 map-get($basic-zi-colors, 'accent-dark');
	border-radius: 0.375rem;
}

.btn-dateperiod {
	border: 0.125rem solid black !important;
	background: white !important;
	color: black !important;
	border-radius: 0.938rem !important;

	&:hover {
		color: #404040 !important;
	}
}

.lbl-filters {
	@include theme-aware('color', 'text-primary');
	font-size: $text-xs;

	&:hover {
		color: #404040 !important;
	}
}

.large-popover {
	width: 50rem; /* Adjust the width as needed */
	// height: 600px; /* Adjust the height as needed */
}

.large-popover > .pf-c-popover__content {
	width: 50rem; /* Adjust the width as needed */
	height: 100%; /* Adjust the height as needed */
}

.button-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: nowrap;
	overflow-x: auto;
}

.period-selector-nav-btn {
	border: none;
	background-color: transparent;
	font-size: $text-xl;
	position: relative;
	top: 39%;

	&:disabled {
		color: gray;
	}

	&.nav-btn-right {
		left: 20px;
	}
}

.pull-left {
	float: left;
}

.pull-right {
	float: right;
}

.lbl-dates-selected {
	display: flex;
	justify-content: center;
	align-items: center;
}

.text-center {
	text-align: center;
}

.theme-simple-dark .just-info .tile-content .svg-inline--fa {
	color: black !important;
}

.theme-simple-dark .just-info {
	color: black !important;
}

.lbl-date {
	cursor: pointer;
}

h1 {
	font-size: $text-xxl;
	font-weight: bold;
}

h2 {
	font-size: $text-lg;
	font-weight: bold;
}

h3 {
	font-size: $text-md;
}

p {
	font-size: $text-sm;
}

h1.sub-heading {
	margin-top: 1rem;
	margin-bottom: 0.25rem;
}

.sub-heading-description {
	margin-bottom: 0.25rem;
}

// limit height of treeview select component within modals
.pf-c-modal-box .pf-c-modal-box__body .pf-c-select__menu .pf-c-tree-view .pf-c-tree-view__list {
	max-height: 25.125rem;
	overflow-y: auto;
}

.pf-c-modal-box .pf-c-modal-box__body .pf-c-select__menu .pf-c-tree-view .pf-c-tree-view__list,
.selectSeries,
.selectFolder,
.pf-c-modal-box .pf-c-modal-box__body .pf-c-select__menu .pf-c-tree-view .pf-c-tree-view__list {
	max-height: 18.125rem;
	overflow-y: auto;
}

.svg-folder-space {
	margin-right: $text-xs;
}

.no-innerscroll > .pf-c-select__menu {
	overflow-y: hidden;
	min-height: 100%;
	max-height: 100%;
}

.no-innerscroll
	> .pf-c-select__menu
	> .pf-c-tree-view
	> .pf-c-tree-view__list
	> li
	> .pf-c-tree-view__list {
	overflow-y: hidden;
}

.no-innerscroll
	> .pf-c-select__menu
	> .pf-c-tree-view
	> .pf-c-tree-view__list
	> .pf-m-expanded
	.pf-c-tree-view__content:not(:nth-last-child(1)) {
	pointer-events: none;
	opacity: 0.5;
}

.btn-dp-close {
	padding: 0.25rem 0.5rem !important;
	border: solid 0.063rem #dfdfdf !important;
	left: 0.625rem;
	background: white !important;
	bottom: 0.063rem;
}

.pf-c-alert.pf-m-danger > .pf-c-alert__icon > svg {
	color: red;
}

.pf-c-empty-state {
	background: white;
}

.zi-table-row:hover {
	cursor: pointer;
	@include theme-aware('background', 'card-background');
}

.data-builder-drop-grid-container.with-share {
	position: relative;
	bottom: 28px;
}

.lbl-entity-actions {
	position: relative;
	bottom: 35px;
}

.dual-list-v2-container {
	padding-top: 40px;
	max-height: 300px;
}

.tbl-dual-list-container {
	max-height: 280px;
	overflow-y: auto;
}

.tb-dual-search {
	margin-bottom: 20px;
}

.tbl-dual-list {
	border: solid 1px gray;
}

.tbl-dual-list > tbody > tr {
	height: 45px;

	td {
		cursor: pointer;
		padding: 10px;
	}

	.empty-row {
		height: 45px !important;
	}
}

.dual-list-row-selected {
	td {
		@include theme-aware('background', 'brand-primary');
		color: white !important;
	}

	svg {
		color: white !important;
	}

	input[type='checkbox'] {
		border: solid 1px white;
	}
}

.dv-dual-list-actions {
	position: relative;
	left: 80px;
	top: 122px;
}

.grp-search {
	height: 37px;
}

.modal-share-footer {
	margin-top: 100px;
}

.first-empty-td {
	width: 310px !important;
}

.slim-variant-container.with-share {
	position: relative;
	bottom: 28px;
}

.grp-search > span {
	position: relative;
	left: 3px;
	border-right: none;
	top: -1px;
	border-left: none;
}

.edit-dashboard {
	position: relative;
	right: 45px;
}

.h1-restricted-access {
	font-size: 48px !important;
	font-weight: bold;
}

.btn-home-back {
	position: relative;
	bottom: 110px;
}

.faSignOutHover {
	color: #0073c3 !important;
}

.fa-cx {
	font-size: 1.5rem !important;
}

.stacked-icon {
	color: black;
	padding-top: 35px;
}

.invalid {
	padding: 8px;
	border: 0.125rem solid map-get($basic-zi-colors, 'error-red-border');
	color: map-get($basic-zi-colors, 'error-red-text');
	button {
		color: map-get($basic-zi-colors, 'error-red-text');
	}
}

.bold {
	font-weight: bold;
}

.bolder {
	font-weight: bolder !important;
}

.hidden,
.temp-hidden {
	display: none !important;
}

.dual-list-search {
	display: flex;
	gap: 5rem;
}

.dual-list-search-input {
	width: 42.5% !important;
}

.dual-list-search-label {
	display: flex;
	gap: 10.5rem;
}

.disable-join {
	cursor: not-allowed;
	padding: 8px;
	border: 0.125rem solid darkgray;
	color: darkgray;
	button {
		color: darkgray;
	}
}

.fullwidth-dropdown {
	min-width: 100% !important;

	button {
		min-width: 100% !important;
		background: white;
	}
}

.pf-m-error {
	color: #c9190b !important;
}

.required-input {
	border-bottom-color: #c9190b;
}

.disabled-dropdown-item {
	cursor: not-allowed;
	opacity: 0.5;
}

.cond-format-modal-body > .pf-c-modal-box__body {
	min-height: 355px;
}

//wizywyg style overrides
.rdw-colorpicker-modal {
	width: 200px !important;
	height: 210px !important;
}

.lbl-and {
	padding-left: 10px;
	padding-top: 4px;
}

//update modal button icons to be white for light theme
.modal-icon {
	color: white !important;
}

.del-row-rule {
	position: relative;
	left: 83%;
	min-width: 25px !important;

	svg {
		color: white !important;
	}
}

input:disabled {
	color: black !important;
}

.pull-right > .pf-c-form__group-control {
	float: right;
}

.p-sub {
	padding-left: 15px;
	padding-top: 15px;
}

.dv-dual-list-no-modal {
	width: 62%;
	padding-left: 10px;
}

.fs-large {
	font-size: large;
}

.submit-button-container {
	display: flex;
	justify-content: flex-end;
	margin-top: 16px;
}

.submit-button-container button {
	width: 110px;
}

.display-inline {
	display: flex;
	align-items: center;
}

.pointer {
	cursor: pointer;
}

.non-modal-dual-list-search {
	position: relative;
	right: 30px;
}

.th-date {
	min-width: 125px !important;
}

.extra-treeview-height > ul > li > ul {
	max-height: 100% !important;
	overflow-y: auto;
}
